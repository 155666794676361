
              @import "@/assets/css/variables/variables.scss";
            

@import '@/assets/css/pages/deposit/default.scss';
@import '@/assets/css/components/deposit/multiButton.scss';
.form_main {
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }
  .clearfix {
    margin-bottom: 40px;
  }
}
.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto !important;
  position: relative;
  .width_img {
    position: absolute;
    right: 0;
    top: -30px;
    height: 120px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
